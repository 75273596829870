var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main",attrs:{"id":"print"}},[_c('v-container',[_c('v-row',[_c('v-container',[_c('h2',[_vm._v("รายการบิล")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dataTable,"items-per-page":10,"search":_vm.searchTxt},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mx-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Order Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"ค้นหา","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.searchTxt),callback:function ($$v) {_vm.searchTxt=$$v},expression:"searchTxt"}})],1)],1)]},proxy:true},{key:"item.LineTotal",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.FormatNumber(item.LineTotal)))])]}},{key:"item.AmountQtyTotal",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.FormatNumber(item.AmountQtyTotal)))])]}},{key:"item.DiscountTotal",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.FormatNumber(item.DiscountTotal)))])]}},{key:"item.Total",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.FormatNumber(item.Total)))])]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-3 primary--text",on:{"click":function($event){return _vm.editLine(item)}}},[_vm._v(" mdi-cog-transfer-outline ")])]}},{key:"item.Status",fn:function(ref){
var item = ref.item;
return [(item.Status == 0)?_c('span',[_vm._v("ยกเลิก")]):_vm._e(),(item.Status == 1)?_c('span',[_vm._v("ใช้อยู่")]):_vm._e(),(item.Status == 2)?_c('span',[_vm._v("จ่ายแล้ว")]):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }